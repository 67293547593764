@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=DM+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.App {
  position: relative;
  overflow: hidden;
  text-align: center;
  background-color: #242424;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.AppWrapper {
  flex: 1;
  overflow-y: hidden;
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: 1px solid #1f2428;
}

.PageWrapper {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

* {
  -webkit-tap-highlight-color: transparent;
}

@media only screen and (min-width: 960px) and (orientation: landscape) {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb:vertical {
    margin: 5px;
    background-color: #999;
    -webkit-border-radius: 5px;
  }

  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    height: 5px;
    display: block;
  }
}
